export const menuItems = 
[
  {
    "id": 1,
    "name": "API Library",
    "url": "/apis",
    "subMenu": [
      {
        "id": 2,
        "name": "AMD Platform APIs",
        "subMenu": [
          {
            "id": 3,
            "name": "API Background",
            "url": "/docs/oamd/intro"
          },
          {
            "id": 4,
            "name": "AMD Use Cases",
            "subMenu": [
              {
                "id": 5,
                "name": "Get User Token",
                "url": "/docs/oamd/use-cases/get-user-token"
              },
              {
                "id": 6,
                "name": "Get Program and Project ID",
                "url": "/docs/oamd/use-cases/get-program-or-project-id"
              },
              {
                "id": 7,
                "name": "Search for Samples",
                "url": "/docs/oamd/use-cases/search-for-samples"
              },
              {
                "id": 8,
                "name": "Create Samples",
                "url": "/docs/oamd/use-cases/create-samples"
              },
              {
                "id": 9,
                "name": "Update Metadata on Sample",
                "url": "/docs/oamd/use-cases/update-metadata-on-sample"
              },
              {
                "id": 10,
                "name": "Upload Genomic Data",
                "url": "/docs/oamd/use-cases/upload-genomic-data"
              },
              {
                "id": 11,
                "name": "Run Workflow",
                "url": "/docs/oamd/use-cases/run-workflow"
              },
              {
                "id": 12,
                "name": "View Workflow Runs",
                "url": "/docs/oamd/use-cases/view-workflow-runs"
              },
              {
                "id": 13,
                "name": "Download Workflow Run Results",
                "url": "/docs/oamd/use-cases/download-workflow-run-results"
              },
              {
                "id": 14,
                "name": "Authenticate user against Azure and get AMDP Token",
                "url": "/docs/oamd/use-cases/get-auth-token"
              }
            ]
          },
          {
            "id": 15,
            "name": "API Specifications",
            "subMenu": [
              {
                "id": 16,
                "name": "Schemas",
                "url": "/docs/oamd/api/sample-service"
              },
              {
                "id": 17,
                "name": "Schemas",
                "url": "/docs/oamd/api/user-service"
              },
              {
                "id": 18,
                "name": "Schemas",
                "url": "/docs/oamd/api/workflow-service"
              }
            ]
          }
        ]
      },
      {
        "id": 19,
        "name": "NIO Autocoder",
        "subMenu": [
          {
            "id": 20,
            "name": "Changelog",
            "url": "/docs/nio-autocoder/CHANGELOG"
          },
          {
            "id": 21,
            "name": "Access",
            "url": "/docs/nio-autocoder/access"
          },
          {
            "id": 22,
            "name": "NIO Autocoder API",
            "url": "/docs/nio-autocoder/api"
          },
          {
            "id": 23,
            "name": "API Guidance",
            "url": "/docs/nio-autocoder/guidance"
          },
          {
            "id": 24,
            "name": "Purpose",
            "url": "/docs/nio-autocoder/purpose"
          }
        ]
      },
      {
        "id": 25,
        "name": "DEX Upload API",
        "subMenu": [
          {
            "id": 26,
            "name": "API Overview",
            "url": "/docs/dex-uploadapi/api-overview"
          },
          {
            "id": 27,
            "name": "Updates, Version, & Change History",
            "url": "/docs/dex-uploadapi/change-history"
          },
          {
            "id": 28,
            "name": "Documentation",
            "url": "/docs/dex-uploadapi/documentation"
          },
          {
            "id": 29,
            "name": "Getting Started",
            "url": "/docs/dex-uploadapi/get-started"
          },
          {
            "id": 30,
            "name": "Questions, Help & Support",
            "url": "/docs/dex-uploadapi/help-and-support"
          }
        ]
      }
    ]
  }
]
;
