import React from "react";
import { useParams } from "react-router-dom";
import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import SidebarNav from "../../components/SidebarNav/SidebarNav";
import { ROUTES } from "../../helpers/menus/routeConstants";
import { menuItems } from "../../helpers/menus/docsSidebarMenuItems";
import "swagger-ui-react/swagger-ui.css";
import SwaggerLoader from "./SwaggerLoader";
import { filterDocsSidebar } from '../../helpers/menus/filterDocsSidebar'; 

// TODO: Have the APPID be brought in from the GETAPI's call used to populate the list of API's to pass in for loading the swagger file.
const appId = ""
const apiName = {
  "dex-uploadapi": "DEX Upload API",
  "nio-autocoder": "NIO Autocoder",
  "oamd": "AMD Platform APIs"
};

const SwaggerView = () => {
  const { slug } = useParams();

  let breadcrumbs = [
    { name: "Home", link: "/" },
    { name: "API Library", link: ROUTES["API Library"] },
    { name: apiName[slug], link: `/docs/${slug}/api-overview` },
    // { name: sectionNames[section], link: `/docs/${slug}/documentation` },
    { name: "Swagger View" },
  ];

  const filteredMenuItems = filterDocsSidebar(menuItems, slug);
  

  return (
    <>
      <PrimaryNav />
      <Breadcrumbs crumbs={breadcrumbs} />
      <div className="grid-row grid-gap padding-x-5">
        <SidebarNav menuItems={filteredMenuItems} />
        <div className="tablet:grid-col-6 markdown">
          <SwaggerLoader appId={appId}/>
        </div>
      </div>
    </>
  );
};

export default SwaggerView;
