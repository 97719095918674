export function filterDocsSidebar(menuItems, slug) {
  const apiLibrary = menuItems.find((item) => item.name === "API Library");
  if (!apiLibrary) return [];

  const filteredApiLibrary = { ...apiLibrary };

  // Filter the subMenu so only the API that matches the slug remains.
  // Each subMenu item (e.g. "DEX Upload API") has its own subMenu array
  // with actual URLs that start with `/docs/<slug>/...`.
  filteredApiLibrary.subMenu = filteredApiLibrary.subMenu.filter((subItem) => {
    if (!subItem.subMenu) return false;


    return subItem.subMenu.some(
      (page) => page.url && page.url.startsWith(`/docs/${slug}`)
    );
  });

  return [filteredApiLibrary];
}
