// msalConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "a63c3356-470c-470a-bbad-07cfa63c2163",  // B2C App Registration ID in tenant cdcextabac
    authority: "https://cdcextabac.b2clogin.com/cdcextabac.onmicrosoft.com/B2C_1A_XQZ5_DGW_CDC", 
    redirectUri: window.location.origin, // Dynamically use current hostname
    knownAuthorities: ["cdcextabac.b2clogin.com"],  // Required for B2C
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);
