import React from "react";
import { NavLink } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const AuthLink = ({ closeHelpMenu, primaryNavTabClasses }) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    // Request user access token
    const loginRequest = {
      scopes: ["openid", "profile"], 
      // For single-tenant, no need for .default if you're just requesting MS Graph or openid profile
    };

    // This sends the user to Microsoft Entra for sign-in
    instance.loginRedirect(loginRequest);
  };

  const logout = () => {
    instance.logoutRedirect({
      // postLogoutRedirectUri: "https://yourapp.com/",
    });
  }

  const isAuthenticated = useIsAuthenticated();
  return (
    <li
      className="position-relative margin-left-1"
      onMouseEnter={closeHelpMenu}
    >
      <NavLink
        to="#"
        onClick={isAuthenticated ? logout : handleLogin}
        aria-label={ isAuthenticated ? "Logout" : "Login with your CDC credentials"}
        className={primaryNavTabClasses}
      >
        {isAuthenticated ? "Logout" : "Login"}
      </NavLink>
    </li>
  );
};

export default AuthLink;
